import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot,  Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { AdminInfoModel } from '../../models/admin-info.model';


@Injectable()
export class AdminAuthGuard implements CanActivate {
    @Select(state => state.core.adminInfos) adminInfos$: Observable<any>;
    path: ActivatedRouteSnapshot[];
    route: ActivatedRouteSnapshot;
    adminInfos: AdminInfoModel;
  constructor(
      private router: Router,
  ) {
    this.adminInfos$.subscribe( adminInfos => {
      if ( adminInfos ) {
        this.adminInfos = adminInfos;
;      }
    });
  }

  canActivate(): Observable<boolean> | boolean {
    if (this.adminInfos.forfait === 'SUPER') {
        return true;
    } else {
        return false;
    }
  }
}
