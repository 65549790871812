import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  @Select(state => state.core.adminInfos) adminInfos$: Observable<any>;
  menus = [
    { name : 'tickets', label: 'Tickets soporte', icon: 'assets/img/chat.png'},
    { name : 'manage', label: 'Gestión Administradores', icon: 'assets/img/crud.jpeg'},
    { name : 'settings-web', label: 'Ajustes web', icon: 'assets/img/panel.png'},
    { name : 'settings-mobile', label: 'Ajustes mobile', icon: 'assets/img/mobile.png'},
  ];
  selectedMenu;
  constructor() { }

  ngOnInit() {
  }

  selectMenu(menu) {
    this.selectedMenu = menu.name;
  }
}
