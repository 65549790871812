import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DialogLogoutComponent } from '../dialog-logout/dialog-logout.component';
import { ComunidadModel } from '../../model/comunidad.model';
import { GetComunityExtras, SelectComunity } from '../../views/home/store/home.actions';
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { AdminInfoModel } from '../../models/admin-info.model';
import { SentryService } from '../../common/sentry/sentry-message.service';
import { ComunityExtrasModel } from '../../model/comunity-extras.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Select((state) => state.core.selectedComunity) selectedComunity$: Observable<any>;
  @Select((state) => state.core.comunities) comunities$: Observable<any>;
  @Select((state) => state.core.extras) extras$: Observable<any>;
  @Select((state) => state.core.adminInfos) adminInfos$: Observable<any>;
  @Select((state) => state.core.christmasAnimationActive) christmasActive$: Observable<any>;

  @Input() title = '';
  fileNameDialogRef: MatDialogRef<DialogLogoutComponent>;
  firstComunity: ComunidadModel;
  selectedComunity: ComunidadModel;
  selectedComunityName = '';
  adminInfos: AdminInfoModel;
  logo = 'assets/img/logo.png';
  christmasActive = true;
  extras: ComunityExtrasModel;
  constructor(public dialog: MatDialog, private store: Store, private sentryService: SentryService) {}

  onSelected(comunity: ComunidadModel) {
    this.store.dispatch(new SelectComunity(comunity));
    this.selectedComunityName = comunity.name;
  }

  ngOnInit() {
    this.selectedComunity$.subscribe((selectedComunity) => {
      if (selectedComunity) {
        this.selectedComunityName = selectedComunity.name;
        this.store.dispatch(new GetComunityExtras());
      }
    });
    this.christmasActive$.subscribe((christmasActive) => {
      this.christmasActive = christmasActive;
    });
    this.extras$.subscribe((extras) => {
      this.extras = extras;
    });
    this.comunities$.subscribe((comunities) => {
      if (comunities && comunities.length > 0) {
        this.firstComunity = comunities[0];
      }
    });
    this.adminInfos$.subscribe((adminInfos) => {
      if (adminInfos) {
        this.adminInfos = adminInfos;
      }
    });
  }

  menuClicked(menu: string) {
    this.sentryService.sendMessage('MENU_CLICK', menu);
  }
  logout() {
    this.fileNameDialogRef = this.dialog.open(DialogLogoutComponent, {
      hasBackdrop: true,
      minHeight: 150,
      minWidth: 300
    });
  }
}
