import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { NzModalService } from 'ng-zorro-antd';
import { Observable } from 'rxjs';
import { HelpTIcketMessageModel } from '../../../model/help-ticket-message.model';
import { HelpTicketModel } from '../../../model/help-ticket.model';
import { HttpService } from '../../../services/http/http.service';
import { GetHelpTickets, SendTicketMessage } from '../../home/store/home.actions';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent implements OnInit {
  @ViewChild('chatbox', { static: false }) private chatbox: ElementRef;
  @Select((state) => state.core.helpTickets) helpTickets$: Observable<any>;
  tickets: HelpTicketModel[];
  selectedTicket: HelpTicketModel;
  message;
  closedValue;
  sendIcon = 'assets/img/send.png';
  refreshIcon = 'assets/img/refresh.png';
  deleteIcon = 'assets/icon/close.png';
  constructor(private store: Store, private http: HttpService, private modal: NzModalService) {}

  ngOnInit() {
    this.init();
    this.store.dispatch(new GetHelpTickets());
  }

  init() {
    this.helpTickets$.subscribe((helpTickets) => {
      if (helpTickets) {
        this.tickets = helpTickets;
      }
    });
  }
  onCloseTicketChange(event) {
    this.closedValue = !this.closedValue;
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `¿ Está seguro que quiere actuualizar este ticket ?`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.http.thorUpdateTicket(this.selectedTicket.id, this.closedValue).subscribe((response) => {
          this.reloadTicket();
        });
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {
        this.closedValue = !this.closedValue;
      }
    });
  }
  selectTicket(ticket) {
    this.selectedTicket = ticket;
    this.closedValue = this.selectedTicket.closed;
    this.scrollToBottom();
  }
  send(origin: string, target: string, isUser: boolean) {
    if (this.checkCanSend()) {
      this.store.dispatch(new SendTicketMessage(this.selectedTicket.id, origin, target, this.message, isUser));
      setTimeout(() => {
        this.reloadTicket();
      }, 500);
    }
  }
  deleteMessage(message: HelpTIcketMessageModel) {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `¿ Está seguro que quiere borrar este mensaje ? \n << ${message.message} >>}`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        this.http.thorDeleteTicketMessage(message.id).subscribe((response) => {
          this.reloadTicket();
        });
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }
  checkCanSend(): boolean {
    if (this.selectedTicket.closed) {
      this.popMessage('No puedes contestar en un ticket cerrado');
      return false;
    }
    if (!this.message || this.message.length === 0) {
      this.popMessage('Tiene que introducir un mensaje');
      return false;
    }
    return true;
  }
  popMessage(text) {
    this.modal.warning({
      nzTitle: 'AVISO',
      nzContent: `${text}`,
      nzOkText: 'Ok',
      nzOkType: 'primary',
      nzOnOk: () => {}
    });
  }
  reloadTickets() {
    this.message = null;
    this.selectedTicket = null;
    this.store.dispatch(new GetHelpTickets());
  }
  reloadTicket() {
    this.message = null;
    this.http.thorFindHelpTicketById(this.selectedTicket.id).subscribe((ticket) => {
      this.selectedTicket = ticket;
      this.closedValue = this.selectedTicket.closed;
      this.scrollToBottom();
    });
  }

  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.chatbox.nativeElement.scrollTop = this.chatbox.nativeElement.scrollHeight;
      }, 500);
    } catch (err) {
      console.log('error -> ', err);
    }
  }
}
