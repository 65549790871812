import { Injectable } from '@angular/core';
import { ServiceModel } from '../../models/service.model';
import { PhoneModel } from '../../models/phone.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError, tap, mergeMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AdminUserModel } from '../../model/admin-user.model';
import { ComunityExtrasModel } from '../../model/comunity-extras.model';
import { ComunidadModel } from '../../model/comunidad.model';
import { PortalModel } from '../../model/portal.model';
// tslint:disable-next-line: import-blacklist
import { Observable, of } from 'rxjs';
import { Select } from '@ngxs/store';
import { CancelReservaLocalDto } from '../../common/dtos/cancel-reserva-local.dto';
import { SendInviteUserDto } from '../../common/dtos/send-invite-user.dto';

@Injectable()
export class HttpService {
  @Select((state) => state.core.authToken) authToken$: Observable<any>;

  getNewsURL = 'https://backbone.berekstan.com:4433/core/get/news';
  addNewsURL = 'https://backbone.berekstan.com:4433/core/add/news';
  deleteNewsURL = 'https://backbone.berekstan.com:4433/core/delete/news';
  updateNoticiaURL = 'https://backbone.berekstan.com:4433/core/update/noticia';

  // INCIDENCIAS
  getIncidenciasURL = 'https://backbone.berekstan.com:4433/core/admin/get/incidencias';
  changeIncidenciaStateURL = 'https://backbone.berekstan.com:4433/core/update/incidenciaState';
  changeIncOcultoStateURL = 'https://backbone.berekstan.com:4433/core/update/incidenciaOcultoState';
  addAdminIncidenciaURL = 'https://backbone.berekstan.com:4433/core/admin/add/incidencia';

  // USERS
  getUserListURL = 'https://backbone.berekstan.com:4472/core/get/users';
  updateUserStateURL = 'https://backbone.berekstan.com:4433/core/update/userState';

  // SERVICES
  getServicesURL = 'https://backbone.berekstan.com:4433/core/get/services';
  updateServiceURL = 'https://backbone.berekstan.com:4433/core/update/service';
  deleteServiceURL = 'https://backbone.berekstan.com:4433/core/delete/service';
  // PHONES
  getPhonesURL = 'https://backbone.berekstan.com:4433/core/get/phones';
  updatePhoneURL = 'https://backbone.berekstan.com:4433/core/update/phone';
  deletePhoneURL = 'https://backbone.berekstan.com:4433/core/delete/phone';
  // DOCUMENTS
  sendDocURL = 'https://backbone.berekstan.com:4433/core/add/documento';

  // PUSH
  sendNoticiaPushURL = 'https://backbone.berekstan.com:4431/push/news';
  sendIncidenciaPushURL = 'https://backbone.berekstan.com:4431/push/incidencia';
  updateIncidenciaPushURL = 'https://backbone.berekstan.com:4431/push/update';

  // INCIDENCIAS BETWEEN DATES
  incBetweenDatesURL = 'https://backbone.berekstan.com:4433/core/admin/get/incidenciasBD';

  // RESERVATIONS
  padelGetComunity = 'https://backbone.berekstan.com:4430/booking/padel/all/bycode';
  padelCancelOne = 'https://backbone.berekstan.com:4430/booking/padel/disableReserva';
  localGetComunity = 'https://backbone.berekstan.com:4439/booking/local/all/bycode';
  localCancelOne = 'https://backbone.berekstan.com:4439/booking/local/disableReserva';

  // RESERVATIONS PISCINE/SOLARIUM
  poolGetComunity = 'https://backbone.berekstan.com:4490/booking/pool/all/bycode';
  poolCancelOne = 'https://backbone.berekstan.com:4490/booking/pool/disableReserva';
  solariumGetComunity = 'https://backbone.berekstan.com:4492/booking/solarium/all/bycode';
  solariumCancelOne = 'https://backbone.berekstan.com:4492/booking/solarium/disableReserva';

  authToken;
  constructor(private http: HttpClient) {
    this.authToken$.subscribe((authToken) => {
      if (authToken) {
        this.authToken = authToken;
      }
    });
  }

  // MAILING
  getInviteUsersList(code: string): Observable<any> {
    return this.doPostRequest(`${environment.apiMailingUrl}/get-invite-users`, { code, key: environment.mailingAccess.key });
  }
  sendInvitesUsers(dto: SendInviteUserDto): Observable<any> {
    dto.key = environment.mailingAccess.key;
    return this.doPostRequest(`${environment.apiMailingUrl}/send-invite-users`, dto);
  }
  // STATS
  getPadelStats(code: string): Observable<any> {
    return this.doPostRequest(`${environment.apiUrlBookingPadel}/padel/comunity-stats`, { code });
  }
  getSolariumStats(code: string): Observable<any> {
    return this.doPostRequest(`${environment.apiUrlBookingSolarium}/solarium/comunity-stats`, { code });
  }
  getPiscinaStats(code: string): Observable<any> {
    return this.doPostRequest(`${environment.apiUrlBookingPiscina}/pool/comunity-stats`, { code });
  }
  getLocalStats(code: string): Observable<any> {
    return this.doPostRequest(`${environment.apiUrlBookingLocal}/local/comunity-stats`, { code });
  }
  getCoreStats(code: string): Observable<any> {
    return this.doPostRequest(`${environment.apiUrlCore}/find/stats`, { code });
  }
  // ADMIN SUPER ALTA DE DATOS
  fetchAllComunidades(): Observable<any> {
    return this.doAuthPostRequest(`${environment.apiUrlZeus}/get/comunidades`, {});
  }
  addAdminUser(data: AdminUserModel): Observable<any> {
    return this.doAuthPostRequest(`${environment.apiUrlZeus}/add/admin`, { data });
  }
  addComunidad(data: ComunidadModel): Observable<any> {
    return this.doAuthPostRequest(`${environment.apiUrlZeus}/add/comunity`, { data });
  }
  addPortal(data: PortalModel): Observable<any> {
    return this.doAuthPostRequest(`${environment.apiUrlZeus}/add/portal`, { data });
  }

  addPhone(data: PhoneModel): Observable<any> {
    return this.doAuthPostRequest(`${environment.apiUrlZeus}/add/phone`, { data });
  }

  addService(data: ServiceModel): Observable<any> {
    return this.doAuthPostRequest(`${environment.apiUrlZeus}/add/service`, { data });
  }

  addExtras(data: ComunityExtrasModel): Observable<any> {
    return this.doAuthPostRequest(`${environment.apiUrlZeus}/add/extras`, { data });
  }

  getComunityExtras(code: string): Observable<any> {
    return this.doPostRequest(`${environment.apiUrlCore}/get/comunity-extras`, { code });
  }

  /// ************* /////
  doFetchPhones(code: string): Observable<any> {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/get/phones`, { code });
  }
  doFetchServices(code: string): Observable<any> {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/get/services`, { code });
  }
  doFetchUsers(code: string): Observable<any> {
    return this.doAuthPostRequest(`${environment.apiUrlAccount}/admin/get-users`, { code });
  }
  doFetchReservasLocal(code: string): Observable<any> {
    return this.doPostRequest(`${environment.apiUrlBookingLocal}/local/all/bycode`, { code });
  }
  cancelReservaLocalAdmin(payload: CancelReservaLocalDto): Observable<any> {
    return this.doPostRequest(`${environment.apiUrlBookingLocal}/local/admin/disable`, payload);
  }
  doFetchReservasPadel(code: string): Observable<any> {
    return this.doPostRequest(`${environment.apiUrlBookingPadel}/padel/all/bycode`, { code });
  }
  doFetchReservasPiscina(code: string): Observable<any> {
    return this.doPostRequest(`${environment.apiUrlBookingPiscina}/pool/all/bycode`, { code });
  }
  doFetchReservasSolarium(code: string): Observable<any> {
    return this.doPostRequest(`${environment.apiUrlBookingSolarium}/solarium/all/bycode`, { code });
  }

  doFetchIncidencias(code: string): Observable<any> {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/admin/get/incidencias`, { code });
  }
  thorFindHelpTicketById(ticketId: string): Observable<any> {
    return this.doAuthPostRequest(`${environment.migrationApiUrl}/migration/find-help-ticket`, { ticketId });
  }
  thorFindHelpTickets(): Observable<any> {
    return this.doAuthPostRequest(`${environment.migrationApiUrl}/migration/thor/find-help-tickets`, {});
  }
  thorSendTicketMessage(ticketId: string, origin: string, target: string, message: string, isUser: string): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.doAuthPostRequest(`${environment.migrationApiUrl}/migration/thor/add-ticket-message`, { ticketId, origin, target, message, isUser });
  }
  thorDeleteTicketMessage(messageId: string): Observable<any> {
    return this.doAuthPostRequest(`${environment.migrationApiUrl}/migration/thor/delete-ticket-message`, { messageId });
  }
  thorUpdateTicket(ticketId: string, closed: boolean): Observable<any> {
    return this.doAuthPostRequest(`${environment.migrationApiUrl}/migration/thor/update-ticket`, { ticketId, closed });
  }
  getFilesTypes(): Observable<any> {
    return this.doAuthPostRequest(`${environment.migrationApiUrl}/migration/find-files-types`, {});
  }
  getDocumentsByType(code_comunity: string, type: string): Observable<any> {
    return this.doAuthPostRequest(`${environment.migrationApiUrl}/migration/documents-by-type`, { code_comunity, type });
  }
  doFetchNoticias(code: string): Observable<any> {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/get/news/admin`, { code });
  }
  doLogin(email: string, password: string): Observable<any> {
    return this.doPostRequest(`${environment.apiUrlAccount}/admin/login`, { email, password });
  }
  addDocument(document: any) {
    return this.doAuthPostRequest(`${environment.migrationApiUrl}/migration/add-document`, {
      type: document.type,
      code_comunity: document.code_comunity,
      name: document.name,
      fileId: document.fileId,
      forComunity: document.forComunity,
      userIds: document.userIds
    });
  }
  deleteDocument(code_comunity: string, documentId: string) {
    return this.doAuthPostRequest(`${environment.migrationApiUrl}/migration/delete-document`, { code_comunity, documentId });
  }
  addNoticiaRequest(noticia: any) {
    return this.doAuthPostRequest(`${environment.migrationApiUrl}/migration/add-noticia`, noticia);
  }
  requestAddNews(code_comunity: string, title: string, description: string, image) {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/add/news`, { code_comunity, title, description, image });
  }

  requestDeleteNews(code: string, id: string) {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/delete/news`, { code, id });
  }
  requestUpdateIncidencia(code: string, category: string, type: string, id: number, state: number, oculto: number) {
    // tslint:disable-next-line: max-line-length
    return this.doAuthPostRequest(`${environment.migrationApiUrl}/migration/update-incidencia`, { code, category, type, id, state, oculto });
  }
  requestUpdateUsersState(code: string, users: number[], state: number) {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/update/users`, { code, users, state });
  }
  requestChangeIncidenciaState(code: string, id: string) {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/update/incidenciaState`, { code, id });
  }

  requestchangeIncidenciaOcultoState(code: string, id: string) {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/update/incidenciaOcultoState`, { code, id });
  }
  requestUpdateUserState(code: string, id_list: any, action: string) {
    let state;
    switch (action) {
      case 'bloquear':
        state = 1;
        break;
      case 'desbloquear':
        state = 0;
        break;
      case 'suprimir':
        state = 2;
        break;
    }
    return this.doAuthPostRequest(`${environment.apiUrlCore}/update/userState`, { code, state, id_list });
  }
  requestUpdateNoticia(code_comunity: string, id: number, state: number) {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/update/noticia`, { code_comunity, id, state });
  }
  requestSendNoticiaPush(code: string) {
    return this.doAuthPostRequest(`${environment.apiUrlPush}/news`, { code });
  }

  requestSendIncidenciaPush(code: string) {
    return this.doAuthPostRequest(`${environment.apiUrlPush}/incidencia`, { code });
  }
  addAdminIncidencia(code: string, category: string, type: string, description: string) {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/admin/add/incidencia`, { code, category, type, description });
  }

  getIncidenciasBetweenDates(code: string, start: string, end: string) {
    // BASIC AUTHENTICATION
    return this.doAuthPostRequest(`${environment.apiUrlCore}/admin/get/incidenciasBD`, { code, start, end });
  }
  getServices(code: string) {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/get/services`, { code });
  }
  getPhones(code: string) {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/get/phones`, { code });
  }
  updateService(service: ServiceModel) {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/update/service`, { service });
  }

  deleteService(service: ServiceModel) {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/delete/service`, { service });
  }

  updatePhone(phone: PhoneModel) {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/update/phone`, { phone });
  }
  deletePhone(phone: PhoneModel) {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/delete/phone`, { phone });
  }
  sendSolicitudSoporte(name: string, email: string, message: string) {
    return this.doAuthPostRequest(`${environment.apiUrlCore}/solicitud/support`, { name, email, message });
  }
  sendDoc(title: string, admin_email: string, image: string, destinatario: string) {
    // BASIC AUTHENTICATION
    const username = 'client';
    const password = '12345client67890client';
    const headers = new HttpHeaders();

    // POST PARAMETERS
    // let code = 'CVH2015';
    const urlSearchParams = new HttpParams();
    // urlSearchParams.append('code', code_comunity);
    urlSearchParams.append('admin_email', admin_email);
    urlSearchParams.append('title', title);
    urlSearchParams.append('destinatario', destinatario);
    urlSearchParams.append('image', image);
    urlSearchParams.append('Content-Type', 'application/x-www-form-urlencoded');

    //  headers.append("Authorization", "Basic " + btoa(username + ":" + password));
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.doGetRequest(`${environment.apiUrlCore}/add/documento`, { headers, urlSearchParams });
  }

  mailingAuth(): Observable<any> {
    return (
      this.http
        // tslint:disable-next-line: max-line-length
        .post<any>(`${environment.apiMailingAuthUrl}/login`, { email: environment.mailingAccess.user, password: environment.mailingAccess.password })
        .pipe(
          map((response) => {
            return response;
          }),
          catchError((err) => {
            return throwError(err);
          })
        )
    );
  }
  doAuthPostRequest(url: string, body: any): Observable<any> {
    let options;
    if (this.authToken) {
      const HEADERS = new HttpHeaders({
        'x-access-token': this.authToken
      });
      options = {
        headers: HEADERS
      };
    }
    return this.http.post<any>(url, body, options).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  doPostRequestWithHeaders(url: string, body: any, headers: any): Observable<any> {
    return this.http
      .post<any>(url, body, { headers })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err) => {
          return throwError(err);
        })
      );
  }
  doPostRequest(url: string, body: any): Observable<any> {
    return this.http.post<any>(url, body).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  doGetRequest(url: string, params: any): Observable<any> {
    return this.http.get<any>(url, params).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  doAuthGetRequest(url: string, params: any): Observable<any> {
    let options;
    if (this.authToken) {
      const HEADERS = new HttpHeaders({
        'x-access-token': this.authToken
      });
      options = {
        headers: HEADERS
      };
    }
    return this.http.get<any>(url, options).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}

function throwError(err: any): import('rxjs/Observable').ObservableInput<any> {
  throw new Error(err);
}
