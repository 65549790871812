import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import * as Sentry from '@sentry/browser';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ComunidadModel } from '../../model/comunidad.model';

@Injectable()
export class SentryService {
  @Select((state) => state.core.selectedComunity) selectedComunity$: Observable<any>;
  selectedComunity: ComunidadModel;
  constructor() {
    Sentry.init({
      dsn: environment.sentryDSN
    });
    this.selectedComunity$.subscribe((selectedComunity) => {
      if (selectedComunity) {
        this.selectedComunity = selectedComunity;
      }
    });
  }
  logNewNoticia(title: string) {
    const payload = {
      key: 'NEW_NOTICIA',
      title,
      timestamp: new Date().toISOString()
    };
    // tslint:disable-next-line: max-line-length
    Sentry.captureMessage(JSON.stringify({ payload, comunity: { admin: this.selectedComunity.admin_name, name: this.selectedComunity.name, code: this.selectedComunity.code } }));
  }
  logIncidenciaUpdate(oldState: string, newState: string) {
    const payload = {
      key: 'UPDATE_INCIDENCIA',
      oldState,
      newState,
      timestamp: new Date().toISOString()
    };
    // tslint:disable-next-line: max-line-length
    Sentry.captureMessage(JSON.stringify({ payload, comunity: { admin: this.selectedComunity.admin_name, name: this.selectedComunity.name, code: this.selectedComunity.code } }));
  }
  sendMessage(key: string, value: any) {
    const payload = {
      key,
      value
    };
    // tslint:disable-next-line: max-line-length
    Sentry.captureMessage(JSON.stringify({ payload, comunity: { admin: this.selectedComunity.admin_name, name: this.selectedComunity.name, code: this.selectedComunity.code } }));
  }
}
