export class GetNoticias {
  static readonly type = '[NOTICIAS] GetNoticias';
  constructor() {}
}
export class PopNewNoticia {
  static readonly type = '[NOTICIAS] PopNewNoticia';
  constructor(public show) {}
}

export class ChristmasAnimationActive {
  static readonly type = '[ANIMATION] ChristmasAnimationActive';
  constructor(public active) {}
}
export class CancelReservaLocal {
  static readonly type = '[LOCAL] CancelReservaLocal';
  constructor(public payload) {}
}
export class AddNoticia {
  static readonly type = '[NOTICIAS] AddNoticia';
  constructor(public noticia) {}
}
export class GetDocumentsByType {
  static readonly type = '[DOCUMENTS] GetDocumentsByType';
  constructor(public type) {}
}
export class AddDocument {
  static readonly type = '[DOCUMENTS] AddDocument';
  constructor(public document) {}
}
export class DeleteDocument {
  static readonly type = '[DOCUMENTS] DeleteDocument';
  constructor(public documentId, public type) {}
}
export class AfterAddDocument {
  static readonly type = '[DOCUMENTS] AfterAddDocument';
  constructor(public action) {}
}
export class DisableNoticia {
  static readonly type = '[NOTICIAS] DisableNoticia';
  constructor(public id) {}
}
export class PopNewIncidencia {
  static readonly type = '[INCIDENCIAS] PopNewInciencia';
  constructor(public show) {}
}
export class GetIncidencias {
  static readonly type = '[INCIDENCIAS] GetIncidencias';
  constructor() {}
}
export class SelectIncidencia {
  static readonly type = '[INCIDENCIAS] SelectIncidencia';
  constructor(public incidencia) {}
}
export class SelectTraspasoIncidencia {
  static readonly type = '[INCIDENCIAS] SelectTraspasoIncidencia';
  constructor(public incidencia) {}
}
export class UpdateIncidencia {
  static readonly type = '[INCIDENCIAS] UpdateIncidencia';
  constructor(public code: string, public category: string, public type: string, public id: string, public state: string, public oculto: string) {}
}
export class GetServices {
  static readonly type = '[SERVICES] GetServices';
  constructor() {}
}
export class GetFilesTypes {
  static readonly type = '[FILES] GetFilesTypes';
  constructor() {}
}
export class GetUsers {
  static readonly type = '[USERS] GetUsers';
  constructor() {}
}
export class GetLocals {
  static readonly type = '[LOCALS] GetLocals';
  constructor() {}
}
export class GetPadels {
  static readonly type = '[PADELS] GetPadels';
  constructor() {}
}
export class GetPhones {
  static readonly type = '[PHONES] GetPhones';
  constructor() {}
}
export class GetPiscinas {
  static readonly type = '[PHONES] GetPiscinas';
  constructor() {}
}
export class GetSolariums {
  static readonly type = '[PHONES] GetSolariums';
  constructor() {}
}
export class GetComunity {
  static readonly type = '[COMUNITY] GetComunity';
  constructor(public code: string) {}
}
export class SaveComunities {
  static readonly type = '[COMUNITY] SaveComunities';
  constructor(public comunities) {}
}
export class SaveAdminInfos {
  static readonly type = '[COMUNITY] AdminInfos';
  constructor(public adminInfos) {}
}
export class SaveAuthToken {
  static readonly type = '[COMUNITY] AuthToken';
  constructor(public authToken) {}
}
export class SelectComunity {
  static readonly type = '[COMUNITY] SelectComunity';
  constructor(public comunity) {}
}

export class UpdateUsersState {
  static readonly type = '[COMUNITY] UpdateUsersState';
  constructor(public id_list, public action: string) {}
}
export class ClearAllStores {
  static readonly type = '[ICOMUNITY] ClearAllStores';
  constructor() {}
}
export class GetHelpTickets {
  static readonly type = '[THOR] GetHelpTickets';
  constructor() {}
}

export class SendTicketMessage {
  static readonly type = '[THOR] SendTicketMessage';
  constructor(public ticketId: string, public origin: string, public target: string, public message: string, public isUser: boolean) {}
}
export class UpdateTicket {
  static readonly type = '[THOR] UpdateTicket';
  constructor(public ticket) {}
}
export class DeleteTicketMessage {
  static readonly type = '[THOR] DeleteTicketMessage';
  constructor(public messageId: string) {}
}

export class GetStats {
  static readonly type = '[CORE] GetStats';
  constructor() {}
}

export class GetComunityExtras {
  static readonly type = '[CORE] GetComunityExtras';
  constructor() {}
}
/*
export class PickupFromLocker {
  static readonly type = '[CONSIGNACUSTOMER] PickupFromLocker';
  constructor(public data: PickUpCodeDto) {}
}

export class DropoffConfirmation {
  static readonly type = '[CONSIGNACUSTOMER] DropoffConfirmation';
  constructor(public data: {id: string, code: string}) {}
}
 */
