import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ComunityModel } from '../../models/comunity.model';
import { AdminInfosModel } from '../../model/admin-infos.model';
import { HttpService } from '../../services/http/http.service';
import { SessionService } from '../../services/session/session.service';
import { Store } from '@ngxs/store';
import { ClearAllStores, SaveAdminInfos, SaveAuthToken, SaveComunities } from '../home/store/home.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  logged: boolean = false;

  model: any = {};
  loading = false;
  returnUrl: string;
  admin_info: ComunityModel;
  wrong_data: boolean = false;
  listComunity: ComunityModel[];

  constructor(private route: ActivatedRoute, private router: Router, private sessionService: SessionService, private httpService: HttpService, private store: Store) {}

  ngOnInit() {
    this.hasSession();
  }

  login() {
    this.store.dispatch(new ClearAllStores());
    this.httpService.doLogin(this.model.username, this.model.password).subscribe((response) => {
      if (response && response.result) {
        const auth_token = response.result[0]['auth_token'];
        const adminInfos: AdminInfosModel = response.result[0];
        this.store.dispatch(new SaveAuthToken(auth_token));
        this.store.dispatch(new SaveAdminInfos(adminInfos));
        this.store.dispatch(new SaveComunities(response.result));
        this.sessionService.login();
      }
    });
  }
  hasSession() {
    if (this.sessionService.isLoggedIn()) {
      this.router.navigate(['home']);
    } else {
      this.sessionService.clearAll();
    }
  }
}
