import { Component, OnInit } from '@angular/core';
import { ComunityModel } from '../../models/comunity.model';
import { StorageService } from '../../services/storage/storage.service';
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { GetComunityExtras, GetStats } from './store/home.actions';
import { ComunidadModel } from '../../model/comunidad.model';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { ComunityExtrasModel } from '../../model/comunity-extras.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(private storageService: StorageService, private store: Store) {}
  @Select((state) => state.core.adminInfos) adminInfos$: Observable<any>;
  @Select((state) => state.core.selectedComunity) selectedComunity$: Observable<any>;
  @Select((state) => state.core.stats) stats$: Observable<any>;

  USER_ACCOUNT_TYPE = 'NORMAL';
  showMenu = true;
  listComunity: ComunityModel[];
  stats: any;
  selectedComunity: ComunidadModel;
  statsLocal = {
    users: null,
    news: null
  };
  todayDate = null;
  public pieChartOptions = {
    responsive: true,
    legend: { position: 'bottom' }
  };
  extras: ComunityExtrasModel;

  public statsIncidenciasLabels: string[] = ['Pendientes', 'En progreso', 'Solucionadas'];
  public statsIncidenciaData: number[] = [];
  public pieChartType = 'pie';
  ngOnInit() {
    this.init();
    this.loadTodayDate();
  }

  getForfait() {
    this.USER_ACCOUNT_TYPE = this.storageService.getForfait();
  }
  init() {
    this.selectedComunity$.subscribe((selectedComunity) => {
      if (selectedComunity) {
        this.selectedComunity = selectedComunity;
        this.store.dispatch(new GetStats());
      }
    });
    this.stats$.subscribe((stats) => {
      if (stats) {
        this.stats = stats;
        this.statsLocal.users = this.formatStatsPValue(stats.core.UsersCount);
        this.statsLocal.news = this.formatStatsPValue(stats.core.NewsCountTotal);
        // tslint:disable-next-line: max-line-length
        this.statsIncidenciaData = [stats.core.IncidenciasPendientesCountMonth, stats.core.IncidenciasProgresoCountMonth, stats.core.IncidenciasSolucionadasCountMonth];
      }
    });
  }

  formatStatsPValue(value: number): string {
    return `${(value * 100) / 1000}`;
  }

  loadTodayDate() {
    this.todayDate = moment(new Date())
      .locale(environment.defaultLang)
      .format('ll');
  }
}
