import { Injectable } from '@angular/core';
import { AlertService } from './alert/alert.service';

@Injectable()
export class UtilsService {
  constructor(private alertSrvice: AlertService) {}
  public validateEmail(email: string): boolean {
    const regex = new RegExp('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$');
    if (email && regex.test(email)) {
      return true;
    }
    this.alertSrvice.popNotification('info', 'error', 'Formato del email invalido');
    return false;
  }

  public onDateFilterChange(event, originalData: any[]): any[] {
    let month;
    const year = new Date().getFullYear();
    let result;
    let values;
    switch (event.value) {
      case 'this':
        month = new Date().getMonth();
        result = originalData;
        values = [];
        result.forEach((item) => {
          const itemmonth = new Date(item.day).getMonth();
          const itemyear = new Date(item.day).getFullYear();
          if (itemmonth === month && itemyear === year) {
            values.push(item);
          }
        });
        return values;
      case 'prev':
        month = new Date().getMonth() - 1;
        result = originalData;
        values = [];
        result.forEach((item) => {
          const itemmonth = new Date(item.day).getMonth();
          const itemyear = new Date(item.day).getFullYear();
          if (itemmonth === month && itemyear === year) {
            values.push(item);
          }
        });
        return values;
      case 'year':
        result = originalData;
        values = [];
        result.forEach((item, index, array) => {
          const itemyear = new Date(item.day).getFullYear();
          if (itemyear === year) {
            values.push(item);
          }
        });
        return values;
      case 'interval':
        values = [];
        return values;
      case 'todo':
        return Object.assign(originalData);
    }
  }
}
