import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { AddNoticia, PopNewNoticia } from '../../home/store/home.actions';
import { AlertService } from '../../../services/alert/alert.service';
import { environment } from '../../../../environments/environment';
import { SentryService } from '../../../common/sentry/sentry-message.service';
import { ComunidadModel } from '../../../model/comunidad.model';
import { NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-new-noticia',
  templateUrl: './new-noticia.component.html',
  styleUrls: ['./new-noticia.component.scss']
})
export class NewNoticiaComponent implements OnInit {
  @Select((state) => state.core.popNewNoticia) popNewNoticia$: Observable<any>;
  @Select((state) => state.core.selectedComunity) selectedComunity$: Observable<any>;
  validateForm: FormGroup;
  visible = false;
  fileUploadUrl = `${environment.migrationApiUrl}/file-uploader/upload-file`;
  selectedComunity: ComunidadModel;
  closing = false;

  constructor(private fb: FormBuilder, private store: Store, private alertService: AlertService, private sentryService: SentryService, private modal: NzModalService) {
    this.validateForm = this.fb.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      image: ['', []],
      publicUrlPath: ['', []]
    });
  }

  ngOnInit() {
    this.selectedComunity$.subscribe((selectedComunity) => {
      if (selectedComunity) {
        this.selectedComunity = selectedComunity;
      }
    });
    this.popNewNoticia$.subscribe((popNewNoticia) => {
      this.visible = popNewNoticia;
    });
  }
  handleChange({ file, fileList }): void {
    const status = file.status;
    if (status !== 'uploading') {
      console.log('FILE_UPLOADING -> ', file, fileList);
    }
    if (status === 'done') {
      console.log('FILE_DONE -> ', file, fileList);
      if (file && file.response) {
        this.validateForm.controls.publicUrlPath.patchValue(file.response.publicUrlPath);
      }
      this.alertService.popNotification('success', 'Tratamiento archivo', 'Archivo guardado correctamente');
    } else if (status === 'error') {
      this.alertService.popNotification('error', 'Tratamiento archivo', 'Hubo un error guardando el archivo');
    }
  }
  sentryLogNewNoticia(title: string) {
    this.sentryService.logNewNoticia(title);
  }
  sendNoticia(): void {
    this.modal.confirm({
      nzTitle: 'CONFIRMACIÓN',
      nzContent: `¿ Va a enviar una nueva noticia a toda la comunidad de ${this.selectedComunity.name}?`,
      nzOkText: 'Confirmar',
      nzOkType: 'primary',
      nzOnOk: () => {
        if (this.validateForm.valid) {
          const dto = {
            title: this.validateForm.get('title').value,
            description: this.validateForm.get('description').value,
            image: this.validateForm.get('image').value ? this.validateForm.get('image').value : '',
            publicUrlPath: this.validateForm.get('publicUrlPath').value ? this.validateForm.get('publicUrlPath').value : ''
          };
          this.sentryLogNewNoticia(dto.title);
          this.store.dispatch(new AddNoticia(dto));
          this.close();
        }
      },
      nzCancelText: 'Cancelar',
      nzOnCancel: () => {}
    });
  }
  resetForm(e: MouseEvent): void {
    if (e) {
      e.preventDefault();
    }
    this.validateForm.reset();
    this.store.dispatch(new PopNewNoticia(false));
  }

  close() {
    this.closingAnimation();
    setTimeout(() => {
      this.resetForm(null);
    }, 400);
  }

  closingAnimation() {
    this.closing = true;
  }
}
