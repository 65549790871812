import { Component, OnInit ,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'app-dialog-logout',
  templateUrl: './dialog-logout.component.html',
  styleUrls: ['./dialog-logout.component.css'],

})
export class DialogLogoutComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogLogoutComponent>,
    private sessionService: SessionService,
    @Inject(MAT_DIALOG_DATA) public data: any ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public logout(){
    this.dialogRef.close();
    this.sessionService.logout();
  }

  public dismiss(){

   this.dialogRef.close();
  }

}
