import { Injectable } from '@angular/core';

@Injectable()
export class ModalService {
    visibleLoadingModal = false;
    visiblePhoneModal = false;
    visibleServiceModal = false;
    alert = {success: true, visible: false};
    DEFAULT_ALERT_TIMER = 3000;
    constructor(

    ) {}
    isLoadingModal(): boolean {
        return this.visibleLoadingModal;
    }
    showLoadingModal(timer?: number) {
        if ( timer ) {
            this.visibleLoadingModal = true;
            setTimeout(() => {
                this.visibleLoadingModal = false;
            }, timer);
        }
            this.visibleLoadingModal = true;
    }
    hideLoadingModal() {
         this.visibleLoadingModal = false;
    }
    showPhoneModal() {
        this.visiblePhoneModal = true;
    }
    hidePhoneModal() {
        this.visiblePhoneModal = false;
    }
    showServiceModal() {
        this.visibleServiceModal = true;
    }
    hideServiceModal() {
        this.visibleServiceModal = false;
    }
    showAlert(success: boolean, timer?: number) {
        const TIMER = timer ? timer : this.DEFAULT_ALERT_TIMER;
        this.alert.success = success;
        this.alert.visible = true;
        setTimeout(() => {
            this.alert.visible = false;
        }, TIMER);
    }
}
